import * as React from 'react'
import { v1 as uuidv1 } from 'uuid'
import { styled } from '../styled'

interface JSONToHTMLTableProps {
  data: any
}

const getElemement = (value) => {
  const type = typeof value
  switch (type) {
    case 'string':
    case 'number':
      return value
    case 'boolean':
      return value.toString()
    case 'undefined':
      return null
    case 'object': {
      const isArray = Array.isArray(value)
      if (!isArray) {
        if (value === {} || !value) return null
        return (
          <Table key={uuidv1()}>
            <tbody>
              {Object.keys(value).map((item) => (
                <Tr key={uuidv1()}>
                  <Th>{item}</Th>
                  <Td>{getElemement(value[item])}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )
      } else {
        if (typeof value[0] === 'object') {
          return mountTable(value)
        } else
          return (
            <Table key={uuidv1()}>
              <tbody>
                {value.map((item) => (
                  <Tr key={uuidv1()}>
                    <Td>{item}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          )
      }
    }
    default:
      return ''
  }
}

const mountTable = (data = []) => {
  return (
    <Table key={uuidv1()}>
      <thead>
        <Tr>
          {Object.keys(data[0]).map((col) => (
            <Th key={uuidv1()}>{getCol(col)}</Th>
          ))}
        </Tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <Tr key={uuidv1()}>
            {Object.keys(data[0]).map((col) => (
              <Td>{getElemement(row[col])}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </Table>
  )
}

const getCol = (key) => {
  const name = key.split('_').join(' ')
  return name
}

export function JSONToHTMLTable({ data }: JSONToHTMLTableProps) {
  if (data === null) return
  const tables = []

  if (!Array.isArray(data) && typeof data === 'object') {
    if (data === {}) return
    const keys = Object.keys(data)
    const resourceTable = []

    keys.forEach((resource, i) => {
      console.log(resource)
      const isSubArray = Array.isArray(data[resource])
      if (isSubArray) {
        resourceTable.push(
          <>
            <Tr key={uuidv1()}>
              <Th>{resource}</Th>
            </Tr>
            <Tr key={uuidv1()}>
              <Td>{JSONToHTMLTable({ data: data[resource] })} </Td>
            </Tr>
          </>,
        )
      } else {
        resourceTable.push(
          <>
            <Tr key={uuidv1()} style={{ position: 'relative' }}>
              <Th>{resource}</Th>
            </Tr>

            <Tr>
              <Td>{JSONToHTMLTable({ data: data[resource] })}</Td>
            </Tr>
          </>,
        )
      }
    })
    tables.push(
      <Table key={uuidv1()}>
        <tbody>{resourceTable}</tbody>
      </Table>,
    )
  } else if (Array.isArray(data)) {
    if (!data?.length) return
    tables.push(mountTable(data))
  } else {
    return getElemement(data)
  }

  return tables
}

const Table = styled.table`
  text-align: left;
  border: 1px dashed #fff;
  width: 100%;
`
const Tr = styled.tr`
  border: 1px dashed #fff;
  width: 100%;
`
const Th = styled.th`
  border: 1px dashed #fff;
  color: #fff;
  width: 100px;
`
const Td = styled.td`
  border: 1px dashed #fff;
  color: #fff;
  width: 100px;
`